import React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage, GatsbyImage, getImage} from "gatsby-plugin-image"
import {Footer} from "../components/footer"
import { Helmet } from "react-helmet"
import SingleElement from "../components/single-element"
import { AnchorLink } from "gatsby-plugin-anchor-links";


function PostTemplate({ data }) {
  const post = data.allSanityPost.nodes[0]
  const image = post.mainImage?.asset?.url;
  const content = post.row1.map(item=>item?.row||null).filter(item=>item);
  return (
    <div>
       <Helmet>
          <title>0039 studio - {post.title}</title>
        </Helmet>
        <div class="min-h-screen  bg-white-100 flex lg:flex-row flex-col lg:justify-center " style={{
        backgroundColor:"rgba(255,255,255,0.3)",
        backgroundImage:"url("+image+")", 
        backgroundSize:"cover", 
        backgroundPosition:"center",
         backgroundBlendMode:"lighten"}}>
        <div class="lg:min-h-screen lg:w-5/12 lg:min-w-0 min-w-full bg-white 2xl:pl-40 xl:pl-40 lg:pl-20 lg:pt-20 flex flex-col z-10">
        <div class="lg:pl-0 lg:absolute lg:top-20 lg:pt-0 pt-5 pb-5 lg:pb-10 flex justify-center">
          <AnchorLink to="/#menu" stripHash>
              <StaticImage
                src="../images/logo.png"
                height="100"
                placeholder="tracedSVG"
                quality="100"
                alt="head-logo"
              ></StaticImage>
              </AnchorLink>
          </div>
          <div class="text-black opacity-30 mt-auto pb-20 lg:block hidden">
            <ul class="uppercase font-bold top-menu 2xl:text-title-main xl:text-title-middle lg:text-title-normal text-6xl ">
            <Link to="/work">
                <li class="transition transform hover:translate-x-6 cursor-pointer">
                  WORK
                </li>
              </Link>
              <Link to="/studio">
                <li class="transition transform hover:translate-x-6 cursor-pointer">
                  STUDIO
                </li>
              </Link>
              <Link to="/about">
                {" "}
                <li class="transition transform hover:translate-x-6 cursor-pointer">
                  ABOUT
                </li>
              </Link>
            </ul>
          </div>
        </div>
        <div class="min-h-screen lg:w-7/12 lg:min-w-0 min-w-full  lg:flex lg:bg-opacity-30 2xl:pl-40 xl:pl-40 lg:pl-20   flex flex-row  lg:justify-start justify-center">
        
          {/* <div class="top-menu 2xl:text-title-main xl:text-title-middle lg:text-title-normal font-bold lg:absolute lg:top-40 lg:right-20 lg:text-6xl md:text-5xl text-4xl flex flex-col justify-center "> */}
          <div class="mt-auto pb-20 lg:flex hidden">
            <div class="font-bold top-menu 2xl:text-title-main xl:text-title-middle lg:text-title-normal text-6xl  flex-column max-w-2xl whitespace-pre-wrap">
            <div>{(post.title||"").split("<").join("\n")}</div>
              <div>{(post.claim||"").split("<").join("\n")}</div>
            </div>
            </div>
            <div class="lg:hidden flex text-white text-center min-w-full flex flex-col justify-center font-bold md:text-5xl text-4xl whitespace-pre-wrap">
            <div>{(post.title||"").split("<").join("\n")}</div>
              <div>{(post.claim||"").split("<").join("\n")}</div>
            </div>
          {/* </div> */}
        </div>
      </div>
      
      <div class="2xl:pl-40 xl:pl-40 lg:pl-20 pl-10 
      margin-setts 2xl:pr-40 xl:pr-40 lg:pr-20 pr-10 lg:mt-60 mt-20 grid
       md:grid-cols-2
        sm:grid-cols-1 
        2xl:gap-60
        xl:gap-40
        lg:gap-20
        gap-20
        
        2xl:text-4xl 
        xl:text-4xl
        lg:text-3xl
        text-3xl
        font-bold 
         pb-20">
         {content.filter(arr=>arr[0]).map(([elem1=null,elem2=null], indexRow)=>{
           if(elem2==null){
            return <SingleElement element={elem1} index={""+indexRow+"1"} classes={'lg:col-span-2'}></SingleElement>
           }else{
              //dos
              let elem1Content= <SingleElement element={elem1} index={""+indexRow+"1"}></SingleElement>
              let elem2Content= <SingleElement element={elem2} index={""+indexRow+"2"}></SingleElement>
              
              return [elem1Content,elem2Content];
           }
          })}
           
        
        </div>


      <Footer></Footer>

    </div>
  )
}

export const pageQuery = graphql`
query($slug: String!) {
  allSanityPost(filter: { slug: {current: { eq: $slug } }}) {
    nodes {
      title,
      claim,
      slug {
        current
      }
        mainImage {
          asset {
            url
          }
        },
        row1{

          row{
          ... on SanityImage{
            asset{
              url,
              __typename
            },
            
          }
          ... on SanityHoverPicElement {
            __typename
            mainImage{
              asset{
                url
              }
            }
            hoverImage{
              asset{
                url
              }
            }
          }


          ... on SanityTextArea{
            text,
            __typename
          }

          ... on SanitySpotifyElement{
            __typename
            image {
              asset{
                url,
                __typename
              }
            }
            spotify {
              text
            }
            
            
          }
        
        }
      }
    }
    
  }
}
`;


export default PostTemplate