import * as React from "react";
import { Link } from "gatsby";
import HoverImage from "./hover-image";
import SpotifyElement from "./spotify-element";

const SingleElement = ({ element, index, classes }) => {

  if (element?.asset?.url) {
    return (
      <div class={"flex justify-center "+classes}>
        <img
          
          style={classes=="col-span-2"?{ height: "80%" }:{ width: "100%" }}
          src={element?.asset?.url}
          alt=""
        />
      </div>
    );
  } else if (element.__typename == "SanityTextArea") {
    //check if element.text contains html
    if (element.text.includes("<")) {
      const fixedHtml = element.text.replace(/\\/g, "");
      return <div class={""+classes}><div  dangerouslySetInnerHTML={{ __html: fixedHtml }} /></div>;
    } else {
      return <div class={"2xl:text-2xl xl:text-2xl lg:text-work-text-normal text-work-text-normal font-normal whitespace-pre-wrap "+classes}>{element.text}</div>;
    }
  } else if (element.__typename == "SanityHoverPicElement") {
    const mappedElement = {
      mainImage: element.mainImage,
      hoverImage: element.hoverImage,
    };

    return <HoverImage el={mappedElement} index={index}></HoverImage>;
  }else if (element.__typename == "SanitySpotifyElement") {
    return <SpotifyElement el={element} index={index} spotifyEmbed={element.spotify.text}></SpotifyElement>

  }
  return <div></div>;
};

export default SingleElement;
