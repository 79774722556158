import * as React from "react";
import { Link } from "gatsby";

const SpotifyElement = ({ el, index, spotifyEmbed }) => {
  let isHover = false;
  const mainImage = el.image?.asset?.url;

  const toggleHover = (element) => {
    const found = window.document.getElementById("spot-container-" + index);
    if (found) {
      const imageHeight=window.document.getElementById("spot-image-" + index).height;
      window.document.getElementById("spot-element-" + index).style.display =
        isHover ? "block" : "none";
        window.document.getElementById("spot-element-" + index).style.height=imageHeight+"px";
        window.document.getElementById("spot-image-" + index).style.display =
        isHover ? "none" : "block";
    }
  };
  const hoverOn = (element) => {
    isHover = true;
    toggleHover(element);
  };

  const hoverOff = (element) => {
    isHover = false;
    toggleHover(element);
  };

  const boxStyle = {
    minHeight: "300px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div>
      <div
         onMouseEnter={hoverOn}
         onMouseLeave={hoverOff}
        id={"spot-container-" + index}
        style={boxStyle}
      >
        <img
          id={"spot-image-" + index}
          style={{ width: "100%" }}
          src={mainImage}
          alt=""
        />
        <iframe
          src={spotifyEmbed}
          id={"spot-element-" + index}
          style={{display:"none", height:"100%"}}
          width="100%"
          height="100%"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
      </div>
    </div>
  );
};

export default SpotifyElement;
