import * as React from "react";
import { Link } from "gatsby";

const HoverImage = ({ el, index }) => {
  let isHover = false;
  const url = el?.slug?.current;
  const mainImage = el.mainImage?.asset?.url;
  const hoverImage = el.hoverImage?.asset?.url;
  let imageToUse = isHover ? hoverImage : mainImage;

  const toggleHover = (element) => {
    imageToUse = isHover ? hoverImage : mainImage;
    const found = window.document.getElementById("hov-container-" + index);
    if (found) {
      found.style.backgroundImage = `url(${imageToUse})`;
    }
  };
  const hoverOn = (element) => {
    isHover = true;
    toggleHover(element);
  };

  const hoverOff = (element) => {
    isHover = false;
    toggleHover(element);
  };

  const boxStyle = {
    backgroundImage: "url(" + imageToUse + ")",
    minHeight: "600px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  return (
    <div>
      <Link to={url}>
        <div
          onMouseEnter={hoverOn}
          onMouseLeave={hoverOff}
          class={"hover:opacity-100 flex items-end"}
          id={"hov-container-" + index}
          style={boxStyle}
        ></div>
      </Link>
    </div>
  );
};

export default HoverImage;
